import { render, staticRenderFns } from "./transaction.vue?vue&type=template&id=26f6acc5&scoped=true"
import script from "./transaction.vue?vue&type=script&lang=js"
export * from "./transaction.vue?vue&type=script&lang=js"
import style0 from "./transaction.vue?vue&type=style&index=0&id=26f6acc5&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26f6acc5",
  null
  
)

export default component.exports