<template>
	<div class="container-vipLevel">
		<Header title="VIP Level" />
		<div class="container-content">
			<div class="container-box" v-for="item in vipData" :key="item.vipLevelId">
				<img src="@/assets/images/current.png" class="container-current" alt="" v-if="myVip == item.vipLevelId">
				<img :src="require('@/assets/images/level' + item.vipLevelId + '.png')" alt="" class="container-levelImg" />
				<div class="container-infoBox">
					<h2>{{item.vipName}}</h2>
					<h3>USDT {{item.vipPrice}}</h3>
					<p>VIP {{item.vipLevelId}} Optimization App</p>
					<ul>
						<li>Receive a set of {{item.taskCount}} apps data tasks</li>
						<li>Profit of {{item.pointReward}}% per application</li>
						<li>Activate with {{item.vipPrice}}USDT</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from "@/components/Header.vue";
	export default {
		components: {
			Header
		},
		data() {
			return {
				myVip: 1, //我的VIP等级
				vipData: []
			};
		},
		methods: {},
		mounted() {
			this.getAjax("api/en/xjkzjiwfnggbshq/ppwosnfhzuwka/getBalance", {}, "get").then((res) => {
				this.userInfo = res.data;
				this.myVip = this.userInfo.vipLevel;
				//获取VIP等级信息
				this.getAjax('api/en/insmfnujwakzjxcb/pwofkzhhhsng/page', {}, 'get').then(res => {
					this.vipData = res.data.list;
				});
			});
		},
	};
</script>

<style lang="less" scoped>
	.container-vipLevel {
		padding-top: 90px;

		.container-content {
			padding: 0 15px 15px;
			line-height: 1.6;

			.container-box {
				border-bottom: 2px solid #8ee0dc;
				padding: 10px;
				display: flex;
				align-items: center;
				position: relative;

				.container-current {
					width: 60px;
					position: absolute;
					right: 0;
					top: 10px;
				}

				&:last-child {
					border-bottom: 0;
				}

				.container-levelImg {
					width: 75px;
				}

				.container-infoBox {
					flex: 1;
					padding-left: 5px;

					h2 {
						font-size: 14px;
					}

					h3 {
						font-size: 14px;
						color: #28acc4;
					}

					p {
						font-size: 13px;
						color: #28acc4;
					}

					ul {
						li {
							font-size: 12px;
							color: #888;

							&::before {
								content: "";
								width: 4px;
								height: 4px;
								background: #888;
								border-radius: 100px;
								display: inline-block;
								vertical-align: middle;
								margin-right: 3px;
							}
						}
					}
				}
			}
		}
	}
</style>